import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { Spinner, TableCustom } from '../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';
import { loadAllDevis } from '../../helpers/actions/projects';
import get from 'lodash/get';
import { dateFormatter, moneyFormatter } from '../../helpers/formatters';
import { UserData } from '../../helpers/api';

class DevisList extends Component {
  state = {
    loading: false,
    devis: [],
    user: null,
    devisPreview: null,
    devisDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { dispatch, project } = this.props;

    dispatch(
      loadAllDevis(project._id, {
        include: ['user'],
        fields: ['title', 'created_at', 'user', 'status', 'base', 'price']
      })
    )
      .then(({ data }) => this.setState({ devis: data, loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.load();
    UserData()
      .then(({ user }) => {
        this.setState({ user: user._id });
      })
      .catch(err => {});
  }

  getDevisData() {
    let { devis } = this.state,
      { project, basePath, t } = this.props;

    return devis.map((d, key) => {
      return {
        className: '',
        data: [
          { className: '', fixed: true, value: get(d, 'title', '') },
          {
            className: '',
            fixed: true,
            value: dateFormatter(d.updated_at || d.created_at)
          },
          {
            className: '',
            fixed: false,
            value: get(d.user, 'displayName', '')
          },
          { className: '', fixed: false, value: t(get(d, 'status', '')) },
          {
            className: 'text-center',
            fixed: false,
            value: d.base ? <i className="fa fa fa-check" /> : null
          },
          {
            className: 'text-right',
            fixed: false,
            value: moneyFormatter(get(d, 'price.proHt', 0))
          },
          {
            className: 'text-right',
            fixed: false,
            value: moneyFormatter(
              get(d, 'price.proHt', 0) + get(d, 'price.materials', 0)
            )
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Link
                key={`details-${key}`}
                id={`details-${key}`}
                className="btn btn-icon ml-1 btn-info btn-sm"
                to={`/${basePath}/${project._id}/devis/${d._id}/details`}
              >
                <i className="fa fa-eye icon-action" />
              </Link>,
              <UncontrolledTooltip
                key={`tooltip-details-${key}`}
                placement="left"
                target={`details-${key}`}
                delay={0}
              >
                <Trans>Details</Trans>
              </UncontrolledTooltip>
            ]
          }
        ]
      };
    });
  }

  render() {
    let { loading } = this.state,
      { t } = this.props;
    let rows = this.getDevisData();

    return (
      <Card key="devisList" className={'card-flex'}>
        {loading ? <Spinner /> : null}
        <CardHeader>
          <h6>
            <Trans>Devis</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          <TableCustom
            minWidth={767}
            className={'devi-item-material-table'}
            accordion={true}
            heads={[
              { value: t('Title') },
              { value: t('Date') },
              { value: t('User') },
              { value: t('Status') },
              { value: t('Base'), className: 'text-center' },
              { value: t('Pro HT'), className: 'text-right' },
              { value: t('Pro HT + Material'), className: 'text-right' },
              { value: '', className: 'text-right' }
            ]}
            rows={rows}
            emptyMessage={t('No devis found')}
          />
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(DevisList));

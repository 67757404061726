import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';

class LocationModal extends Component {
  state = {
    project: null,
    user: null,
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    mode: 'TRANSIT'
  };

  load(project, user) {
    let p = null;
    let u = null;

    if (
      project &&
      project.location &&
      project.location.lat &&
      project.location.lng &&
      user &&
      user.lat &&
      user.lng
    ) {
      p = { lat: project.location.lat, lng: project.location.lng };
      u = { lat: user.lat, lng: user.lng };
    }

    this.setState({ project, user, p, u });
  }

  componentWillMount() {
    let { project, user } = this.props;
    this.load(project, user);
  }

  cleanModal() {
    this.setState({
      project: null,
      user: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  initMap(mode = 'TRANSIT') {
    try {
      let { p, u } = this.state;
      if (p && u) {
        let gm = window.google.maps;

        let directionsService = new gm.DirectionsService();
        let directionsDisplay = new gm.DirectionsRenderer();

        let haight = new gm.LatLng(u.lat, u.lng);
        let oceanBeach = new gm.LatLng(p.lat, p.lng);

        let mapOptions = {
          zoom: 8,
          center: haight ? haight : 'Paris'
        };

        let map = new gm.Map(document.getElementById('mapRoute'), mapOptions);
        //let map = new gm.Map(this.refs['mapNew' + project._id], mapOptions);

        directionsDisplay.setMap(map);

        let request = {
          origin: haight,
          destination: oceanBeach,
          travelMode: gm.TravelMode[mode]
        };

        directionsService.route(request, (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
            this.setState(prevState => {
              return { ...prevState, mode };
            });
          }
        });
      }
    } catch (err) {}
  }

  componentDidMount() {
    this.initMap();
  }

  render() {
    let { project, mode, p, u } = this.state;
    let { t } = this.props;

    return (
      <Modal size="lg" isOpen={!!project} toggle={() => this.closeModal()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {t('Road between your address and the project')}
        </ModalHeader>
        <ModalBody style={{ position: 'relative' }}>
          {u && p ? (
            <div>
              <div className={'text-center'}>
                <Button
                  color={mode === 'TRANSIT' ? 'info' : 'default'}
                  onClick={() => this.initMap('TRANSIT')}
                >
                  <Trans>TRANSIT</Trans>
                </Button>
                <Button
                  color={mode === 'DRIVING' ? 'info' : 'default'}
                  onClick={() => this.initMap('DRIVING')}
                >
                  <Trans>DRIVING</Trans>
                </Button>
                <Button
                  color={mode === 'BICYCLING' ? 'info' : 'default'}
                  onClick={() => this.initMap('BICYCLING')}
                >
                  <Trans>BICYCLING</Trans>
                </Button>
                <Button
                  color={mode === 'WALKING' ? 'info' : 'default'}
                  onClick={() => this.initMap('WALKING')}
                >
                  <Trans>WALKING</Trans>
                </Button>
              </div>

              <div id={'mapRoute'} style={{ width: '100%', height: 500 }} />
            </div>
          ) : (
            <p className={'text-not-found text-center'}>
              <Trans>Project or User don't have the address configured</Trans>
            </p>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(LocationModal));
